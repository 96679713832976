import React, { useState, useEffect, useContext, useMemo } from "react";
import "./RightOrder.css";
import axios from "axios";
import { toast } from "react-toastify";
import DataContext from "../../store/DataProvider";
import save from "../../assets/icons/save.svg";
import cancel from "../../assets/icons/cancel.svg";
import { validateInvalidChar } from "../../functions/validateInvalidChar";
import DataListInput from "react-datalist-input";

const RightOrder = () => {
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [packages, setPackages] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    clientId: "",
    packageId: "",
    invoiceNumber: "",
    particulars: "",
    amount: "",
    discount: "",
    totalAmount: "",
    gst: 18,
    finalAmount: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    getClients();
    getPackages();
    calculateDiscount();
    if (context.editActive === "order-edit") {
      setOrderDetails({
        clientId: context.orderData.clientId,
        packageId: context.orderData.packageId,
        invoiceNumber: context.orderData.invoiceNumber,
        particulars: context.orderData.particulars,
        amount: context.orderData.amount,
        discount: context.orderData.discount,
        totalAmount: context.orderData.totalAmount,
        gst: context.orderData.gst,
        finalAmount: context.orderData.finalAmount,
      });
    }
  }, [
    context.editActive === "order-edit",
    orderDetails.discount,
    orderDetails.amount,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const getClients = async () => {
    try {
      const response = await axios("https://api.theeliteqa.com/api/client/", {
        headers: {
          token: token,
        },
      });
      if (response.data !== undefined) {
        setClients(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPackages = async () => {
    try {
      const response = await axios("https://api.theeliteqa.com/api/package", {
        headers: {
          token: token,
        },
      });
      if (response.status === 200) {
        setPackages(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createOrder = async () => {
    try {
      setLoading(true);
      const body = {
        orderDetails,
      };
      const response = await axios.post(
        "https://api.theeliteqa.com/api/order/create",
        body.orderDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Order created successfully.");
        setOrderDetails({
          clientId: "",
          packageId: "",
          invoiceNumber: "",
          particulars: "",
          amount: "",
          discount: "",
          totalAmount: "",
          gst: "",
          finalAmount: "",
        });
      }
      setLoading(false);
    } catch (error) {
      toast.error("Failed to create order.");
      setLoading(false);
    }
  };

  const updateOrder = async () => {
    try {
      setLoading(true);
      const body = {
        orderDetails,
      };
      const response = await axios.put(
        `https://api.theeliteqa.com/api/order/update/${context.orderId}`,
        body.orderDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Order updated successfully.");
        context.changeEditActive("");
        setOrderDetails({
          clientId: "",
          packageId: "",
          invoiceNumber: "",
          particulars: "",
          amount: "",
          discount: "",
          totalAmount: "",
          gst: "",
          finalAmount: "",
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update client.");
    }
  };

  const cancelEdit = () => {
    context.changeEditActive("");
    context.addOrderId("");
    context.changeActive("vieworders");
    setOrderDetails({
      clientId: "",
      packageId: "",
      invoiceNumber: "",
      particulars: "",
      amount: "",
      discount: "",
      totalAmount: "",
      gst: "",
      finalAmount: "",
    });
  };

  const calculateDiscount = () => {
    let price = Number(orderDetails.amount);
    let discount = Number(orderDetails.discount) / 100;
    let totalAmount = price - price * discount;
    let gst = 18 / 100;
    let finalAmount = totalAmount + totalAmount * gst;
    setOrderDetails({
      ...orderDetails,
      totalAmount: totalAmount,
      finalAmount: finalAmount.toFixed(2),
    });
  };

  const validateData = () => {
    if (orderDetails.discount === "") {
      return toast.error("Please enter discount.");
    } else if (Number(orderDetails.discount) > 100) {
      return toast.error("Discount must be upto 100.");
    } else {
      createOrder();
    }
  };

  const onSelectClient = (selectedItem) => {
    let id = selectedItem._id;
    setOrderDetails({ ...orderDetails, clientId: id });
  };

  const clientItem = useMemo(
    () =>
      clients.map((option) => ({
        key: option.id,
        label: option.companyName,
        ...option,
      })),
    [clients] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSelectPackage = (selectedItem) => {
    let id = selectedItem._id;
    setOrderDetails({ ...orderDetails, packageId: id });
  };

  const packageItem = useMemo(
    () =>
      packages.map((option) => ({
        key: option.id,
        label: option.name,
        ...option,
      })),
    [packages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const validateDiscount = () => {
    if (Number(orderDetails.discount) > 100) {
      return "Discount must be upto 100%.";
    } else {
      return true;
    }
  };

  return (
    <div className="right-order">
      <div className="right-order-container">
        <div className="heading">
          <div className="title">
            <div className="sidebar"></div>
            <span>Add Order Form</span>
          </div>
          <div className="buttons-container">
            {context.editActive === "order-edit" ? (
              <button className="cancel" onClick={cancelEdit}>
                <img src={cancel} alt="save" />
                Cancel
              </button>
            ) : null}
            <button
              className="save"
              style={
                context.editActive === "order-edit" ? { width: "112px" } : null
              }
              onClick={
                context.editActive === "order-edit" ? updateOrder : validateData
              }
              title={context.editActive === "order-edit" ? "Update" : "Save"}
            >
              <img src={save} alt="save" />
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : context.editActive === "order-edit" ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        <div className="form-container">
          <div className="row1">
            <div className="input-container">
              <label>Invoice Number</label>
              <input
                type="text"
                placeholder="Invoice number"
                onChange={(e) =>
                  setOrderDetails({
                    ...orderDetails,
                    invoiceNumber: e.target.value,
                  })
                }
                value={orderDetails.invoiceNumber}
              />
            </div>

            <div className="input-container">
              <label>Client *</label>
              <DataListInput
                placeholder="Client"
                items={clientItem}
                onSelect={onSelectClient}
                dropdownClassName="dropdown"
                itemClassName="dropdownItem"
                activeItemClassName="activeItem"
              />
            </div>

            <div className="input-container">
              <label>Package *</label>
              <DataListInput
                placeholder="Package"
                items={packageItem}
                onSelect={onSelectPackage}
                dropdownClassName="dropdown"
                itemClassName="dropdownItem"
                activeItemClassName="activeItem"
              />
            </div>
          </div>

          <div className="row1" style={{ marginTop: 24 }}>
            <div className="input-container">
              <label>Amount (In INR)</label>
              <input
                type="number"
                placeholder="Enter amount"
                onChange={(e) =>
                  setOrderDetails({ ...orderDetails, amount: e.target.value })
                }
                value={orderDetails.amount}
                onKeyDown={validateInvalidChar}
              />
            </div>

            <div className="input-container">
              <label>Discount (In %)</label>
              <input
                type="number"
                placeholder="Enter discount"
                onChange={(e) =>
                  setOrderDetails({ ...orderDetails, discount: e.target.value })
                }
                value={orderDetails.discount}
                maxlength="3"
                onKeyDown={validateInvalidChar}
              />
              {orderDetails.discount !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {validateDiscount()}
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="input-container">
              <label>Total Amount(In INR)</label>
              <input
                type="text"
                placeholder="Enter amount"
                value={orderDetails.totalAmount}
                disabled
              />
            </div>
          </div>

          <div className="row2">
            <div className="input-container">
              <label>Particulars</label>
              <textarea
                value={orderDetails.particulars}
                onChange={(e) =>
                  setOrderDetails({
                    ...orderDetails,
                    particulars: e.target.value,
                  })
                }
                placeholder="Particulars"
              ></textarea>
            </div>

            <div className="column">
              <div className="input-container">
                <label>GST (9% IGST + 9% CGST)</label>
                <input
                  type="text"
                  placeholder="Enter amount"
                  value={orderDetails.gst}
                  disabled
                />
              </div>

              <div className="input-container">
                <label>Final Amount (In INR)</label>
                <input
                  value={orderDetails.finalAmount}
                  type="text"
                  placeholder="Final amount"
                  onChange={(e) =>
                    setOrderDetails({
                      ...orderDetails,
                      finalAmount: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightOrder;
