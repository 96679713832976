import React, { useState, useEffect } from "react";
import "./add_skill.css";
import trash from "../../assets/icons/trash.svg";
import axios from "axios";
import { toast } from "react-toastify";
import tag from "../../assets/icons/tag.svg";
import view from "../../assets/icons/View.svg";
import edit from "../../assets/icons/edit.svg";
import EditSkillPopup from "../edit_skill_popup/EditSkillPopup";
import ViewSkillPopup from "../view_skill_popup/ViewSkillPopup";
import jwtDecode from "jwt-decode";

const RightAddSkill = () => {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setupdateLoading] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [skillId, setSkillId] = useState("");
  const [skill, setSkill] = useState([]);
  const [topics, setTopics] = useState([]);
  const [searchTopic, setSearchTopic] = useState([]);
  const [search, setSearch] = useState("");
  const [userTopic, setUserTopic] = useState([]);
  const [viewData, setViewData] = useState(null);
  const [isViewActive, setIsViewActive] = useState(false);
  const [skillLoading, setSkillLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [editType, setEditType] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getSkills();
    getTopics();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [skillDetails, setSkillDetails] = useState({
    skills: "",
    topics: [],
  });

  const searchTopics = (value) => {
    let filteredTopic = topics.filter((topic) =>
      topic.topic.toLowerCase().includes(value.trim().toLowerCase())
    );
    if (filteredTopic.length === 0) {
      setSearchTopic([
        {
          topic: "No topic found.",
        },
      ]);
    } else {
      setSearchTopic(filteredTopic);
    }
  };

  const getSkills = async () => {
    try {
      setSkillLoading(true);
      const response = await axios(
        "https://api.theeliteqa.com/api/skill/getskill",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setSkill(response.data.skills);
        setTotalPage(Math.floor(response.data.skills.length / 10) + 1);
        setLoading(false);
        setPages(skill.length / 10);
        setSkillLoading(false);
      }
    } catch (error) {
      setSkillLoading(false);
    }
  };

  const getTopics = async () => {
    try {
      const response = await axios(
        "https://api.theeliteqa.com/api/topic/gettopic",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setTopics(response.data.topics);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createSkill = async () => {
    try {
      setLoading(true);
      const body = {
        skillDetails,
      };
      const response = await axios.post(
        "https://api.theeliteqa.com/api/skill/createskill",
        body.skillDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 201) {
        toast.success("Skill created successfully.");
        setSkillDetails({
          skills: "",
          topics: [],
        });
        getSkills();
      }
      setLoading(false);
    } catch (error) {
      if (error.response.data.error.keyValue.skills === skillDetails.skills) {
        toast.error("Skill already exits.");
        setLoading(false);
      } else {
        toast.error("Failed to create skill.");
        setLoading(false);
        console.log(error);
      }
    }
  };

  const deleteSkill = async (id) => {
    try {
      await axios.delete(
        `https://api.theeliteqa.com/api/skill/deleteskill/${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.error("Skill deleted successfully.");
      let updatedData = skill.filter((data) => data._id !== id);
      setSkill(updatedData);
    } catch (error) {
      toast.error("Failed to delete skill.");
    }
  };

  const updateSkill = async () => {
    try {
      setupdateLoading(true);
      const body = {
        skillDetails,
      };
      const response = await axios.patch(
        `https://api.theeliteqa.com/api/skill/update/${skillId}`,
        body.skillDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Skill updated successfully.");
        setIsEditActive(false);
        setSkillDetails({
          skills: "",
          topics: [],
        });
        getSkills();
        setSearch("");
        setupdateLoading(false);
      }
    } catch (error) {
      setupdateLoading(false);
      toast.error("Failed to update skill.");
    }
  };

  const handleEdit = (data) => {
    setIsEditActive(true);
    setSkillId(data._id);
    setSkillDetails({
      skills: data.skills,
      topics: data.topics,
    });
    setUserTopic(data.topics);
  };

  const cancelEdit = () => {
    setIsEditActive(false);
    setSkillId("");
    setSkillDetails({
      skills: "",
      topics: [],
    });
    setUserTopic([]);
    setSearch("");
  };

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setSkillDetails({ ...skillDetails, [name]: value });
  };

  const addTopic = (data) => {
    let obj = { topicId: data };
    const isTopicPresent = userTopic.find(
      (topic) => topic.topicId._id === data._id
    );
    if (!isTopicPresent) {
      setSkillDetails({
        ...skillDetails,
        topics: [...skillDetails.topics, { topicId: data, _id: data._id }],
      });
      setUserTopic([...userTopic, obj]);
    }
  };

  const removeTopic = (data) => {
    let itemRemoved = skillDetails.topics.filter(
      (topic) => topic._id !== data._id
    );
    setSkillDetails({ ...skillDetails, topics: itemRemoved });
    setUserTopic(itemRemoved);
  };

  const handleView = (data) => {
    setIsViewActive(true);
    setViewData(data);
  };

  const validateData = () => {
    if (skillDetails.skills === "") {
      toast.error("Skill cannot be empty");
    } else {
      createSkill();
    }
  };

  const searchData = (e) => {
    searchTopics(e.target.value);
    setSearch(e.target.value);
  };

  const closeView = () => {
    setIsViewActive(false);
    setViewData(null);
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent);
  //     setCurrentPage(pageNumber);
  // }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return skill.slice(startIndex, endIndex);
  };

  // const getPaginationGroup = () => {
  //     let start = Math.floor((currentPage - 1) / 5) * 5;
  //     return new Array(5).fill().map((_, idx) => start + idx + 1);
  // };

  const searchSkills = async () => {
    try {
      setSkillLoading(true);
      const response = await axios.get(
        `https://api.theeliteqa.com/api/skill/search?searchText=${searchText}`,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setSkill(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(skill.length / 10);
        setSkillLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setPageNum(1);
      getSkills();
    } else {
      setSearchText(e.target.value);
    }
  };

  return (
    <div className="add-skill-container">
      <div className="add-topic-form-container">
        <div className="heading">
          <div className="title">
            <div className="sidebar"></div>
            <span>Add Skills</span>
          </div>
          <div className="form">
            <input
              type="text"
              placeholder="Skill Here"
              name="skills"
              onChange={handleChange("skills")}
              value={isEditActive ? "" : skillDetails.skills}
            />
            <button
              onClick={() => {
                validateData();
              }}
              title="Add Skill"
            >
              {loading ? <i class="fa fa-circle-o-notch fa-spin"></i> : "+"}
            </button>
          </div>
        </div>
      </div>
      <div className="list-topic">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>Added Skills</span>
          </div>
          <div className="search-container">
            <input
              type="search"
              placeholder="Search here"
              onChange={(e) => {
                handleSearch(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchSkills();
                }
              }}
            />
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "#384455",
                marginLeft: "20px",
                color: "white",
                cursor: "pointer",
                borderRadius: "2px",
              }}
              onClick={searchSkills}
            >
              Search
            </button>
          </div>
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            {/* show page numbers */}
            {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        {skillLoading ? (
          <div className="loading-container">
            <i
              className="fa fa-circle-o-notch fa-spin fa-5x"
              style={{
                color: "#FF6813",
              }}
            ></i>
          </div>
        ) : (
          <div className="topic-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Skill</th>
                <th>Assign Topics</th>
                <th>Options</th>
              </tr>

              {getPaginatedData()?.map((data, index) => {
                console.log(data.topics);
                return (
                  <tr key={index}>
                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                    <td>{data.skills}</td>
                    <td
                      onClick={() => {
                        handleView(data);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="assign-skills">
                        {data.topics === undefined ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEdit(data);
                              setEditType("assign");
                            }}
                            title="Assign"
                          >
                            Assign
                          </button>
                        ) : data.topics.length === 0 ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEdit(data);
                              setEditType("assign");
                            }}
                            title="Assign"
                          >
                            Assign
                          </button>
                        ) : (
                          <div className="skills">
                            <img src={tag} alt="skill" />
                            {data.topics.map((topic, index) => {
                              if (index < 3) {
                                return (
                                  <span style={{ marginRight: 5 }} key={index}>
                                    {topic.topicId?.topic}
                                    {index === 3 ||
                                    data.topics.length - 1 === index
                                      ? null
                                      : ","}
                                  </span>
                                );
                              } else {
                                return null;
                              }
                            })}
                            {data.topics.length > 3 ? (
                              <span>{`+${data.topics.length - 3} more`}</span>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="options">
                        <div
                          title="Edit"
                          className="edit"
                          onClick={() => {
                            handleEdit(data);
                            setEditType("edit");
                          }}
                        >
                          <img src={edit} alt="trash" />
                        </div>

                        <div
                          title="Delete"
                          className="delete"
                          onClick={() => deleteSkill(data._id)}
                        >
                          <img src={trash} alt="trash" />
                        </div>

                        <div
                          title="View"
                          className="view"
                          onClick={() => handleView(data)}
                        >
                          <img src={view} alt="view" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>

      {isEditActive ? (
        <EditSkillPopup
          searchData={searchData}
          skills={skillDetails.skills}
          handleChange={handleChange}
          cancelEdit={cancelEdit}
          loading={updateLoading}
          updateSkill={updateSkill}
          search={search}
          searchTopic={searchTopic}
          addTopic={addTopic}
          viewData={viewData}
          userTopic={userTopic}
          removeTopic={removeTopic}
          editType={editType}
        />
      ) : null}
      {isViewActive ? (
        <ViewSkillPopup closeView={closeView} viewData={viewData} />
      ) : null}
    </div>
  );
};
export default RightAddSkill;
