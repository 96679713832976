import React from 'react';
import "./Navbar.css";
import logo from "../../assets/logo.svg";
// import avatar from "../../assets/images/avatar.svg";
// import control_down from "../../assets/icons/control_down.svg";


const Navbar = () => {
    return (
        <div className="navbar-container">
            <div className="logo-container">
                <img src={logo} alt="" />
            </div>
            {/* <div className="profile-container">
                <img src={avatar} alt="" />
                <img src={control_down} alt="" />
            </div> */}
        </div>
    );
};

export default Navbar;