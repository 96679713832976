import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./login.css";
import logo from "../../assets/logo.svg";
import "react-toastify/dist/ReactToastify.css";
import RightSidePanel from "./RightSide";
import jwt_decode from "jwt-decode";

const Login = () => {
  let navigate = useNavigate();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordView, setpasswordView] = useState(true);
  const [loading, setloading] = useState(false);

  function isEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    if (!regEmail.test(val)) {
      return "Invalid Email";
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      const decoded = jwt_decode(token);
      if (Date.now() >= decoded.exp * 1000) {
        localStorage.removeItem("jwt");
        navigate("/");
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loginUser = async () => {
    try {
      setloading(true);
      if (email === "") {
        toast("Hey! email can't be empty!", {
          className: "toast-message",
        });
        setloading(false);
      } else if (password === "") {
        toast("Hey! password can't be empty!", {
          className: "toast-message",
        });
        setloading(false);
      } else {
        const res = await axios.post(
          `https://api.theeliteqa.com/api/admin/login`,
          {
            password: password,
            email: email,
          }
        );
        toast.success("Login Successfully.");
        setloading(false);
        navigate("/dashboard");
        localStorage.setItem("token", res.data.token);
      }
    } catch (error) {
      setloading(false);
      toast(error.response.data.error, {
        className: "toast-message",
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="login-container">
        {/*left side container */}
        <div className="left">
          {/*company logo */}
          <img src={logo} alt="company logo" />

          <span>Admin Sign In</span>

          <p>Let’s get started to hire some best talents </p>
          {/*email input container */}
          <div
            style={
              isEmail(email) === "Invalid Email" && email !== ""
                ? { borderColor: "red" }
                : isEmail(email) !== "Invalid Email"
                ? { borderColor: "#00C49A" }
                : {}
            }
            className="input-container"
          >
            <label
              style={
                isEmail(email) === "Invalid Email" && email !== ""
                  ? { color: "red" }
                  : isEmail(email) !== "Invalid Email"
                  ? { color: "#00C49A" }
                  : {}
              }
            >
              Your Email/Username
            </label>
            <input
              value={email}
              type="text"
              placeholder="Enter email"
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          {email !== "" ? (
            <div className="invalid-email">{isEmail(email)}</div>
          ) : (
            <div className="valid-email"></div>
          )}
          {/*password container */}
          <div className="password-container">
            <label>Enter Password</label>
            <input
              placeholder="Enter password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              type={passwordView ? "password" : "text"}
            />
            {/*eye icon */}
            {passwordView ? (
              <svg
                onClick={() => setpasswordView(!passwordView)}
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2171_2043)">
                  <path
                    d="M0.916992 11.0003C0.916992 11.0003 4.58366 3.66699 11.0003 3.66699C17.417 3.66699 21.0837 11.0003 21.0837 11.0003C21.0837 11.0003 17.417 18.3337 11.0003 18.3337C4.58366 18.3337 0.916992 11.0003 0.916992 11.0003Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2171_2043">
                    <rect width="22" height="22" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                onClick={() => setpasswordView(!passwordView)}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                  stroke="#AAAAAA"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                  stroke="#AAAAAA"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>

          {/*login button */}
          <button onClick={loginUser}>
            {loading ? <div className="loader"></div> : "Login"}
          </button>
        </div>

        {/*right side container */}
        <RightSidePanel />
      </div>
    </>
  );
};

export default Login;
