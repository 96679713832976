import React, { useState, useEffect } from "react";
import "./RightManageStaff.css";
import axios from "axios";
import { toast } from "react-toastify";

const RightManageStaff = () => {
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  // const [searchText, setSearchText] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getStaff();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStaff = async () => {
    try {
      setLoading(true);
      const response = await axios("https://api.theeliteqa.com/api/admin/", {
        headers: {
          token: token,
        },
      });
      if (response.data !== undefined) {
        setStaff(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(staff.length / 10);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Failed to get staff details.");
    }
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent);
  //     setCurrentPage(pageNumber);
  // }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return staff.slice(startIndex, endIndex);
  };

  // const getPaginationGroup = () => {
  //     let start = Math.floor((currentPage - 1) / 5) * 5;
  //     return new Array(5).fill().map((_, idx) => start + idx + 1);
  // };

  // const searchStaff = async () => {
  //     try {
  //         setLoading(true);
  //         const response = await axios.post(`https://api.theeliteqa.com/api/staff/search`, { searchText: searchText }, {
  //             headers: {
  //                 token: token,
  //             }
  //         });
  //         if (response.data !== undefined) {
  //             setStaff(response.data.data);
  //             setTotalPage(Math.floor(response.data.data.length / 10) + 1);
  //             setLoading(false);
  //             setPages(staff.length / 10);
  //         }
  //     } catch (error) {
  //         setLoading(false);
  //         console.log(error);
  //     }
  // };

  // const handleSearch = (e) => {
  //     if (e.target.value === "") {
  //         setPageNum(1);
  //         getStaff();
  //     } else {
  //         setSearchText(e.target.value);
  //     }
  // };

  return (
    <div className="list-staff-container">
      <div className="list-staff">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>Manage Staff</span>
          </div>
          {/* <div className="search-container">
                        <input type="search" placeholder='Search here' onChange={(e) => {
                            handleSearch(e);
                        }} />
                        <button style={{
                            width: "100px",
                            height: "40px",
                            background: "#384455",
                            marginLeft: "20px",
                            color: "white",
                            cursor: "pointer",
                            borderRadius: "2px"
                        }} onClick={searchStaff}>Search</button>
                    </div> */}
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            {/* show page numbers */}
            {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        {loading ? (
          <div className="loader-container">
            <i
              class="fa fa-circle-o-notch fa-spin fa-5x"
              style={{
                color: "#FF6813",
              }}
            ></i>
          </div>
        ) : (
          <div className="staff-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Email</th>
                <th>Name</th>
                <th>Job Role</th>
                <th>Username</th>
              </tr>

              {/* {staff.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.email}</td>
                                    <td>{data.fullname}</td>
                                    <td>{data.jobrole}</td>
                                    <td>{data.username}</td>
                                </tr>
                            );
                        })} */}

              {getPaginatedData()?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                    <td>{data.email}</td>
                    <td>{data.fullname}</td>
                    <td>{data.jobrole}</td>
                    <td>{data.username}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightManageStaff;
